<template>
  <div>
    <v-sheet class="pa-4 mb-2" style="border-radius: 10px;">
      <h4 class="title">Новостная лента</h4>
    </v-sheet>
    <v-sheet>
      <PostForm style="max-width: 600px; margin: 0 auto"
                @posted="$store.dispatch('getProjectPosts', {id: $route.params.project_id})"
                v-if="project.permissions.can_see_chat"
                :project_id="project.id"
                :placeholder="'Напиши тут о том, чем вы занимаетесь, круто если вы прикрепите фото :)'"/>
    </v-sheet>

    <div style="max-width: 600px; margin: 0 auto" v-if="posts.length">
      <Post show-last-comment @wasDeleted="$store.dispatch('getProjectPosts', {id: $route.params.project_id})" show-user
            :key="post.id" v-for="post in posts" :post="post" class="mb-3"/>
    </div>
    <div v-else>
      <v-sheet class="d-flex justify-center align-center flex-column pa-4">
        <v-icon>mdi-access-point</v-icon>
        <h4 class="subtitle-1">
          Нет новостей
        </h4>
      </v-sheet>

    </div>
  </div>
</template>

<script>
import Post from "@/components/Feed/Post";
import PostForm from "@/components/Feed/PostForm";

export default {
  name: "ProjectItemFeedComponent",
  components: {PostForm, Post},
  computed: {
    posts() {
      return this.$store.state.projects.currentProjectPosts;
    },
    project() {
      return this.$store.state.projects.currentProject;
    },
  },
  mounted() {
    this.$store.dispatch('getProjectPosts', {id: this.$route.params.project_id});
  }
}
</script>

<style scoped>

</style>
